import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

export const backdropAnimation = trigger('backdropAnimation', [
  state(
    'visible',
    style({
      opacity: 1,
    })
  ),
  state(
    'hidden',
    style({
      opacity: 0,
    })
  ),
  transition('visible => hidden', animate('200ms ease-in')),
  transition('hidden => visible', animate('300ms ease-out')),
]);

export const modalPanelAnimation = trigger('modalPanelAnimation', [
  state(
    'visible',
    style({
      opacity: 1,
      transform: 'translateY(0) scale(1)',
    })
  ),
  state(
    'hidden',
    style({
      opacity: 0,
      transform: 'translateY(-4px) scale(0.95)',
    })
  ),
  transition('visible => hidden', animate('200ms ease-in')),
  transition('hidden => visible', animate('300ms ease-out')),
]);
