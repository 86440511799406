import { Component, EventEmitter, Input, Output } from '@angular/core';
import { backdropAnimation, modalPanelAnimation } from './animations';
import { CommonModule } from '@angular/common';
import { HtmlParser } from '@angular/compiler';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [CommonModule, A11yModule],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
  animations: [backdropAnimation, modalPanelAnimation],
})
export class ConfirmDialogComponent {
  @Input() isOpen: boolean = false;
  @Output() close = new EventEmitter<void>();
  @Output() dialogAction = new EventEmitter<void>();
  @Input() confirmQuestion!: string | HtmlParser;
  @Input({ required: true }) confirmText!: string;
  @Input() bgConfirmButton: 'danger' | 'primary' = 'primary';
  @Input() isDialogLoading: boolean = false;
  @Input() dialogLoadingText: string = 'Loading...';
  @Input() showQuestionGif: boolean = true;
  @Input() showCancelButton: boolean | null = true;
  @Input() disableActionButton: boolean = false;
  @Input() isHTMLQuestion: boolean = false;
  @Input() isInfoDialog: boolean = false;

  clickConfirmAction() {
    this.isOpen = true;
    this.dialogAction.emit();
  }

  closeModal() {
    this.isOpen = false;
    this.close.emit();
  }
}
